<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <div class="input-container width600">
        <input
          v-model="name"
          @keyup.enter.stop="forward"
          required
          type="text"
          class="input-standard-white validation fadeIn-2"
          id="name"
          placeholder="Your name"
        />
        <label for="name">Your name</label>
      </div>

      <IntroModal
        v-if="start"
        :show="show.introModal && start"
        @close="show.introModal = false"
      />

      <WhoAreYouModal
        @setPartner="setPartner"
        @setInitial="
          $router.push('/login').catch((error) => {
            console.log(error)
          })
        "
        @close="show.whoAreYouModal = false"
        v-if="show.whoAreYouModal && start"
        :partnerPersonalDetails="partnerPersonalDetails"
        :partnerCheckoutBasket="partnerCheckoutBasket"
      />
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:externalBackUrl="externalBackUrl"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import IntroModal from './Modals/IntroModal'
import WhoAreYouModal from './Modals/WhoAreYouModal'

export default {
  name: 'ProfileName',
  components: {
    WhoAreYouModal,
    NavQuestion,
    NavQuestionButtons,
    IntroModal
  },
  mounted() {
    this.$emit('progress', '7%')
    if (this.partnerCheckoutBasket && this.partnerPersonalDetails) {
      // partner details present
      this.show.whoAreYouModal = true
    } else {
      // partner details not present
      this.show.introModal = true
    }
  },
  computed: {
    background() {
      if (this.start) return 'bg-personal-details-cookie'
      return 'bg-personal-details'
    },
    isValidated() {
      if (this.name) return true
      return false
    },
    number() {
      return '1'
    },
    start() {
      return this.$router.history.current.name === 'WriteMyWillName'
    },
    partnerPersonalDetails() {
      return this.$store.getters.partnerPersonalDetails
    },
    partnerCheckoutBasket() {
      return this.$store.getters.partnerCheckoutBasket
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    heading() {
      return 'What is your full name?'
    },
    subHeading() {
      return (
        'Please enter your full name including any middle names.  ' +
        'This usually means writing your name as it appears on your passport/driving licence.'
      )
    },
    forwardTo() {
      if (this.start) return '/write_my_will/dob'
      return '/profile/dob'
    },
    backTo() {
      if (this.start) return null
      return '/home'
    },
    externalBackUrl() {
      if (this.start)
        return process.env.NODE_ENV === 'production'
          ? 'https://www.mylastwill.co.uk/'
          : 'http://127.0.0.1:8000/'
      return null
    }
  },
  watch: {
    personalDetails: {
      immediate: true,
      deep: true,
      handler(value) {
        this.name = value.full_name
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.save()
    next()
  },
  data() {
    return {
      name: null,
      show: {
        introModal: false,
        whoAreYouModal: false
      }
    }
  },
  methods: {
    save() {
      if (this.personalDetails) {
        this.$store.commit('personalDetails', { full_name: this.name })
      }
    },
    forward() {
      if (this.isValidated)
        this.$router.push(this.forwardTo).catch((error) => {
          console.log(error)
        })
    },
    setPartner() {
      this.$store.commit('checkoutBasket', {
        coupon: this.$store.getters.partnerCheckoutBasket
      })
      this.$store.commit(
        'personalDetails',
        this.$store.getters.partnerPersonalDetails
      )
      this.show.whoAreYouModal = false
      this.show.introModal = true
    }
  }
}
</script>

<style></style>
