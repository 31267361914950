<template>
  <SlideModal v-show="show" :buttonText="'Get Started'" @close="close">
    <template v-slot:banner>takes<br />15 minutes</template>
    <template v-slot:slide1>
      <div class="explainer-top final">
        <picture>
          <source
            type="image/webp"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Signing.webp
            "
          />
          <source
            type="image/png"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Signing.png
            "
          />
          <img
            src="https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Signing.png"
            alt="my last will"
          />
        </picture>
      </div>
      <div class="explainer-bottom display-flex">
        <div class="explainer-bottom-inner">
          <h3>Sign your Will</h3>
          <p>
            Once your Will has been checked it is ready to be signed. For your
            Will to be legally binding you must sign it in front of two
            witnesses (almost anybody can be a witness, people often ask
            friends, neighbours or work colleagues).
          </p>
        </div>
      </div>
    </template>
    <template v-slot:slide2>
      <div class="explainer-top">
        <picture>
          <source
            type="image/webp"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Main-img.webp
            "
          />
          <source
            type="image/png"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Main-img.png
            "
          />
          <img
            src="https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Main-img.png"
            alt="my last will"
          />
        </picture>
      </div>
      <div class="explainer-bottom display-flex">
        <div class="explainer-bottom-inner">
          <h3 v-if="coupon && coupon.broker">
            Make your
            <span v-if="personalDetails.partner">Mirror</span> Will & protect
            your family
          </h3>
          <h3 v-else>
            Make your
            <span v-if="personalDetails.partner">Mirror</span> Will in 3 simple
            steps
          </h3>

          <p
            v-if="
              coupon &&
              coupon.broker &&
              coupon.price_discount_percentage === 100 &&
              !coupon.site_share_code &&
              !coupon.broker_share_code
            "
          >
            <span v-if="coupon && coupon.broker">
              {{ coupon.broker.company_name }}</span
            >
            have covered the cost of creating your Will with mylastwill.co.uk
            (saving you upto
            <span>{{ jointPrice }}</span> for a joint will). So if you haven't
            made your Will yet, this is a great opportunity to protect your
            family. It only takes 15 minutes and can easily be completed on a
            smartphone or laptop.
          </p>
          <p
            v-else-if="
              coupon &&
              coupon.broker &&
              !coupon.site_share_code &&
              !coupon.broker_share_code &&
              !coupon.partner_code
            "
          >
            As a
            <span>{{ coupon.broker.company_name }}</span> client you are
            entitled to make a single Will for
            <span>{{ couponSinglePrice }}</span> or joint Will for
            <span>{{ couponJointPrice }}</span> with mylastwill.co.uk. This
            market leading Will writing service usually costs
            {{ singlePrice }}/{{ jointPrice }}, so take the opportunity to
            protect your family today.
          </p>
          <p v-else-if="coupon && coupon.broker && coupon.partner_code">
            As a
            <span>{{ coupon.broker.company_name }}</span> client you are
            entitled to make a you mirror Will for
            <span>{{ couponSinglePrice }}</span> with mylastwill.co.uk. This
            market leading Will writing service usually costs {{ jointPrice }},
            so take the opportunity to protect your family today.
          </p>
          <p v-else-if="coupon && coupon.site_share_code">
            As you have been referred from a friend or family member, a discount
            has been applied meaning a single Will now costs
            <span>{{ couponSinglePrice }}</span> or joint Wills for
            <span>{{ couponJointPrice }}</span> with mylastwill.co.uk. It takes
            only 15 minutes to complete and if you need any help along the way
            you can speak to our experts on 01785 338292.
          </p>
          <p v-else-if="coupon && coupon.broker_share_code">
            As you are a friend or family member of an existing user, a discount
            has been applied meaning a single Will now costs
            <span>{{ couponSinglePrice }}</span> or joint Wills for
            <span>{{ couponJointPrice }}</span> with mylastwill.co.uk. It takes
            only 15 minutes to complete and if you need any help along the way
            you can speak to our experts on 01785 338292.<br /><br />
            <span v-if="coupon.broker"
              >Offer in partnership with {{ coupon.broker.company_name }}.</span
            >
          </p>
          <p v-else>
            From start to finish it takes approximately 15 minutes to create
            your Will, if you need any help along the way you can speak to our
            experts on 01785 338292.
          </p>
        </div>
      </div>
    </template>
    <template v-slot:slide3>
      <div class="explainer-top">
        <picture>
          <source
            type="image/webp"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-questions-img.webp
            "
          />
          <source
            type="image/png"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-questions-img.png
            "
          />
          <img
            src="https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-questions-img.png"
            alt="my last will"
          />
        </picture>
      </div>
      <div class="explainer-bottom display-flex">
        <div class="explainer-bottom-inner">
          <h3>Begin by answering the questions...</h3>

          <p>
            The first step is to answer some questions about yourself and your
            family. If you need help along the way you can chat online or talk
            over the phone to our team of experts.
          </p>
        </div>
      </div>
    </template>
    <template v-slot:slide4>
      <div class="explainer-top">
        <picture>
          <source
            type="image/webp"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Checking.webp
            "
          />
          <source
            type="image/png"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Checking.png
            "
          />
          <img
            src="https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Checking.png"
            alt="my last will"
          />
        </picture>
      </div>
      <div class="explainer-bottom display-flex">
        <div class="explainer-bottom-inner">
          <h3>
            Next, we professionally check your
            <span v-if="personalDetails.partner">Mirror</span> Will
          </h3>
          <p v-if="personalDetails.partner">
            Once your Will has been created your Will is then checked in
            conjunction with your partner's Will. Our expert team have years of
            experience having manually drafted over 20,000 Wills, so you are in
            safe hands.
          </p>
          <p v-else>
            Once your Will has been created the checking process can begin. Our
            expert team have years of experience having manually drafted over
            20,000 Wills, so you are in safe hands.
          </p>
        </div>
      </div>
    </template>
    <template v-slot:coupon>
      <p v-if="coupon && coupon.code" class="coupon-reminder">
        Coupon: <span>{{ coupon.code }}</span>
      </p>
    </template>
    <template v-slot:extra>
      <div class="star-rating">
        <i class="fas fa-star"></i><i class="fas fa-star"></i
        ><i class="fas fa-star"></i><i class="fas fa-star"></i
        ><i class="fas fa-star"></i>
        <p>Rated 9.8 out of 10</p>
      </div>
    </template>
  </SlideModal>
</template>

<script>
import SlideModal from '@/common/ui/SlideModal'
import { prices } from '@/common/mixins/prices'

export default {
  name: 'IntroModal',
  props: ['show'],
  mixins: [prices],
  components: {
    SlideModal
  },
  mounted() {
    if (!this.prices.length) this.$store.dispatch('pricesFetch')
  },
  computed: {
    coupon() {
      return this.$store.getters.checkoutBasket.coupon
    },
    prices() {
      return this.$store.getters.prices
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped></style>
