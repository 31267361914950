<template>
  <BaseModalSmall
    v-bind:isValidated="isValidated"
    @confirm="confirm"
    :noSlide="true"
  >
    <template v-slot:header>Who Are You?</template>
    <template v-slot:body>
      <div class="form-group row" style="margin-top: 0">
        <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6">
          <div
            @click.stop="setInitial"
            class="option-home"
            :class="{ selected: selected.initial }"
          >
            <img
              alt="user"
              :src="genderImage(partnerPersonalDetails.partner_gender)"
            />
            <div class="option-home-label">
              <div class="letter"><span>a</span></div>
              <div class="caption">
                {{ partnerPersonalDetails.partner_full_name }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6">
          <div
            @click.stop="setPartner"
            class="option-home"
            :class="{ selected: selected.partner }"
          >
            <img alt="partner" :src="genderImage(partnerPersonalDetails.sex)" />
            <div class="option-home-label">
              <div class="letter"><span>b</span></div>
              <div class="caption">
                {{ partnerPersonalDetails.full_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <p v-html="descriptionText"></p>
    </template>
    <template v-slot:button>
      {{ buttonText }}
    </template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'

export default {
  name: 'WhoAreYouModal',
  components: {
    BaseModalSmall
  },
  props: ['partnerCheckoutBasket', 'partnerPersonalDetails'],
  data() {
    return {
      selected: {
        initial: false,
        partner: false
      }
    }
  },
  computed: {
    isValidated() {
      return this.selected.partner || this.selected.initial
    },
    descriptionText() {
      if (this.selected.partner) {
        return (
          'Our records indicate that your partner has created their Will, now it is your turn. Click ' +
          'continue to begin answering the questions.'
        )
      }
      if (this.selected.initial) {
        return (
          'It looks like you have already started the process of creating your Will, please login to your ' +
          'user area to continue where you left off.'
        )
      }
      return null
    },
    buttonText() {
      if (this.selected.partner) {
        return 'Create Will'
      }
      if (this.selected.initial) {
        return 'Login & Continue'
      }
      return null
    }
  },
  methods: {
    confirm() {
      if (this.selected.partner) {
        this.$emit('setPartner')
        this.$emit('close')
      } else if (this.selected.initial) this.$emit('setInitial')
    },
    genderImage(gender) {
      if (gender === 'Male')
        return 'https://mylastwill.s3.amazonaws.com/static/img/button-gender-male.png'
      return 'https://mylastwill.s3.amazonaws.com/static/img/button-gender-female.png'
    },
    setPartner() {
      this.selected.partner = true
      this.selected.initial = false
    },
    setInitial() {
      this.selected.partner = false
      this.selected.initial = true
    }
  }
}
</script>

<style scoped>
.form-group {
  position: relative;
  margin-top: 25px;
  max-width: 550px;
}

.form-group .option-home {
  position: relative;
  background-color: #fff;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin-bottom: 20px;
  overflow: hidden;
}

.form-group .option-home.selected {
  background-color: #4f9b7e !important;
}

.form-group .option-home img {
  width: 110px;
  margin: 25px auto 0;
}

.form-group .option-home .option-home-label {
  padding: 15px 0 30px;
}

.un-validated {
  background-color: #345a7a !important;
}

.form-group .option-home .option-home-label .letter {
  color: #fff;
  background: #d2d2d2;
  display: -moz-inline-stack;
  display: inline-block;
  height: 24px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  text-align: center;
  line-height: 24px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  min-width: 24px;
  margin-right: 10px;
  padding: 0 5px;
}

.form-group .option-home.selected .option-home-label .caption {
  color: #fff;
}

.form-group .option-home .option-home-label .caption {
  font-family: 'Poppins', sans-serif;
  color: #6a6a6a;
  font-size: 16px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-weight: 500;
  position: relative;
  text-align: center;
  display: inline-block;
}

.form-group .option-home .overlay-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  width: 100%;
  background-color: #345a7a;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 2;
}

.form-group .option-home .overlay-box .content {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.form-group .option-home .overlay-box .content div {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  padding: 0 20px;
}

.form-group .option-home .overlay-box .content h4:hover {
  color: #d9ece5;
}

.form-group .option-home:hover .overlay-box {
  height: 100%;
}

.form-group .option-home:hover .overlay-box .content {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 200ms;
  -o-transition-delay: 200ms;
  transition-delay: 200ms;
}

.form-group .option-home:hover .option-home-label .letter {
  background: #345a7a;
}

@media screen and (max-width: 570px) {
  .form-group .option-home img {
    width: 80px;
    margin: 15px auto 0;
  }

  .form-group .option-home .option-home-label {
    padding: 15px 0 10px;
  }

  .form-group .option-home .option-home-label .letter {
    display: none;
  }

  .form-group .option-home .overlay-box .content div {
    font-size: 13px;
    padding: 0 10px;
  }
}

@media screen and (min-width: 571px) {
  .form-group .option-home:hover,
  .form-group .option-home.selected {
    background-color: #ffffff;
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.1);
    border: none;
  }
}
</style>
