<template>
  <div class="modal is-active" id="modalguide">
    <div class="modal-background" @click="close"></div>
    <div class="modal-card small">
      <section class="explainer-wrapper">
        <div v-if="$slots.banner" class="tick-wrapper">
          <span></span>
        </div>
        <div v-if="$slots.banner" class="tick-text">
          <slot name="banner"> </slot>
        </div>
        <div class="explainer">
          <div id="slider-nav">
            <div class="button next" @click="next">
              <span class="fa fa-chevron-right"></span>
            </div>
            <div class="button prev" @click="previous">
              <span class="fa fa-chevron-left"></span>
            </div>
          </div>

          <div class="inner-wrapper" ref="innerWrapper">
            <div
              @click="next"
              v-if="$slots.slide1"
              id="slide1"
              class="slide display-flex"
            >
              <slot name="slide1"></slot>
            </div>
            <div
              @click="next"
              v-if="$slots.slide2"
              id="slide2"
              class="slide display-flex"
            >
              <slot name="slide2"></slot>
            </div>
            <div
              @click="next"
              v-if="$slots.slide3"
              id="slide3"
              class="slide display-flex"
            >
              <slot name="slide3"></slot>
            </div>
            <div
              @click="next"
              v-if="$slots.slide4"
              id="slide4"
              class="slide display-flex"
            >
              <slot name="slide4"></slot>
            </div>
            <div
              @click="next"
              v-if="$slots.slide5"
              id="slide5"
              class="slide display-flex"
            >
              <slot name="slide5"></slot>
            </div>
          </div>
          <slot name="coupon"></slot>
          <slot name="extra"></slot>

          <div v-show="!validated" class="explainer-button-wrapper">
            <div @click="next" class="btn btn--dark btn-arrow mt30">
              <div class="text">Next</div>
              <i class="fa fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </section>
      <button
        @click="close"
        :class="{ validated: validated }"
        class="btn btn-arrow modal-card-footer plain-modal-card-footer"
      >
        <span class="text">{{ buttonText }}</span
        ><i class="fa fa-chevron-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlideModal',
  data() {
    return {
      validated: false
    }
  },
  props: ['buttonText'],
  computed: {},
  methods: {
    next() {
      var inWrap = this.$refs.innerWrapper

      var animation = inWrap.animate({ left: ['-100%', '-200%'] }, 300)
      animation.onfinish = this.nextOnFinish
    },
    nextOnFinish() {
      var inWrap = this.$refs.innerWrapper

      inWrap.style.left = '-100%'

      var allSlides = document.getElementsByClassName('slide')
      if (
        allSlides[allSlides.length - 2].firstChild.classList.contains('final')
      )
        this.validated = true

      var first = allSlides[0]
      var last = allSlides[allSlides.length]

      inWrap.insertBefore(first, last)
    },
    previous() {
      var inWrap = this.$refs.innerWrapper

      var animation = inWrap.animate({ left: ['-100%', '0%'] }, 300)
      animation.onfinish = this.previousOnFinish
    },
    previousOnFinish() {
      var inWrap = this.$refs.innerWrapper

      inWrap.style.left = '-100%'
      var allSlides = document.getElementsByClassName('slide')
      if (
        allSlides[allSlides.length - 2].firstChild.classList.contains('final')
      )
        this.validated = true

      var first = allSlides[0]
      var last = allSlides[allSlides.length - 1]
      inWrap.insertBefore(last, first)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
